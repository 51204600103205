import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/global/ErrorUI';


import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';


// componets and pages
const Homepage = React.lazy(() => import('./pages/Home'));
const Payments = React.lazy(() => import('./pages/Payments'));
const About = React.lazy(() => import('./pages/About'));
const POS = React.lazy(() => import('./pages/POS'));
const Bills = React.lazy(() => import('./pages/Bills'));
const Invoices = React.lazy(() => import('./pages/Invoices'));
const Careers = React.lazy(() => import('./pages/Careers'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const Documentation = React.lazy(() => import('./pages/Documentation'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Terms = React.lazy(() => import('./pages/Terms'));
const PolicyOfUse = React.lazy(() => import('./pages/PolicyOfUse'));
const Personal = React.lazy(() => import('./pages/Personal'));
const Business = React.lazy(() => import('./pages/Business'));
const NotFound = React.lazy(() => import('./pages/404'))
const NetworkUI = React.lazy(() => import('./components/global/NetworkUI'));
const Whistleblower = React.lazy(() => import('./pages/Whistleblower'))

const App = () => {

  const errorHandler = (err: any, info: any) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  return (

    <Router>

      <UserState>

            <ResourceState>

                <Suspense fallback={loader.MainLoader()}>

                  <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                      <Routes>

                        <Route path='/' element={<Homepage />} />
                        <Route path='/payments' element={<Payments />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/pos' element={<POS />} />
                        <Route path='/bills' element={<Bills />} />
                        <Route path='/invoices' element={<Invoices />} />
                        <Route path='/careers' element={<Careers />} />
                        <Route path='/contact-sales' element={<Contact />} />
                        <Route path='/pricing' element={<Pricing />} />
                        <Route path='/faqs' element={<FAQ />} />
                        <Route path='/documentation' element={<Documentation />} />
                        <Route path='/privacy' element={<Privacy />} />
                        <Route path='/terms' element={<Terms />} />
                        <Route path='/whistleblower' element={<Whistleblower />} />
                        <Route path='/use-of-policy' element={<PolicyOfUse />} />
                        <Route path='/personal' element={<Personal />} />
                        <Route path='/business-account' element={<Business />} />
                        <Route path='*' element={<NotFound />} />
                        <Route path='/no-network' element={<NetworkUI />} />
                        
                      </Routes>

                  </ErrorBoundary>

                </Suspense>

            </ResourceState>

      </UserState>

    </Router>

  )

}

export default App;